<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.stock.toStation.$errors.length }"
                        :label="$t('forms.toStation')"
                        label-for="toStation"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="toStation"
                            v-model="stock.toStation"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.toStation.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.quantity.$errors.length }"
                        :label="$t('forms.transferQuantity')"
                        label-for="quantity"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.quantity"
                            id="quantity"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.quantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.transportSelect.$errors.length }"
                        :label="$t('forms.transport')"
                        label-for="transport"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="transport"
                            v-model="transportSelect"
                            :options="transports"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.transportSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.remark')"
                        label-for="remark"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="stock.remark"
                            id="remark"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.pickSlipLong')"
                        label-for="pickSlip"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.pickSlip"
                            id="pickSlip"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.workOrderLong')"
                        label-for="workOrder"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.workOrder"
                            id="workOrder"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.aircraft')"
                        label-for="aircraft"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.aircraft"
                            id="aircraft"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.eta')"
                        label-for="eta"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="eta"
                            v-model="stock.eta"
                            :enableTimePicker="false"
                            class="form-input"
                            format="dd.MM.yyyy"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6"></b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import Datepicker from '@vuepic/vue-datepicker';

export default {
    components: {
        vSelect,
        Datepicker
    },
    props: {
        stockObject: {Object, required: true},
        action: {type: String, default: null},
        stockItem: {type: String, required: true},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.loadStations();
        this.getEnumList(["delivery_item_transport"], this.transports);
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            transportSelect: {required},
            stock: {
                quantity: {required, numeric},
                toStation: {required}
            },
        };
    },
    data() {
        return {
            stock: this.stockObject,
            stations: [],
            transports: [],
            transportSelect: []
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            if (this.action !== "updateTransfer") {
                return
            }
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.transportSelect) {
                    this.stock.transport = this.transportSelect.id
                }
                this.stock.quantity = this.stock.quantity ? parseInt(this.stock.quantity) : this.stock.quantity
                this.stock.stockItem = this.stockItem
                this.$emit("clearAction");
                this.create(this.$DeliveryItems, this.stock, this.$t("messages.stockItemsUpdated"), null, null, this.error);
            } else {
                this.$emit("clearAction");
            }
        },
        loadStations() {
            const params = {
                pagination: false
            }
            this.$Stations.getCollection({params}).then((response) => {
                response.data["hydra:member"].forEach(element => {
                    this.stations.push(element.name)
                });
            });
        },
        success() {
            this.$router.push(`/stock-items`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

